import { createStore, createHook, createActionsHook, createStateHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

import isEqual from 'lodash/isEqual';

import type {
	TopLevelComment as BaseComment,
	CommentReply as BaseReply,
	ReactionsSummary,
} from '@confluence/inline-comments-queries';

import { getAnnotationPosition } from './helper/commentsDataHelper';

export interface AnnotationStatus {
	annotationId: string;
	isLoaded: boolean;
}

export interface ReplyData extends BaseReply {
	isUnread: boolean;
	wasRemovedByAnotherUser:
		| false
		| CommentActionType.DELETE_COMMENT
		| CommentActionType.RESOLVE_COMMENT_THREAD;
}

export interface CommentData extends BaseComment {
	isUnread: boolean;
	isOpen: boolean; // unresolved comment
	wasRemovedByAnotherUser:
		| false
		| CommentActionType.DELETE_COMMENT
		| CommentActionType.RESOLVE_COMMENT_THREAD;
	replies: ReplyData[];
}

export type CommentsDataState = {
	orderedActiveAnnotationIdList: AnnotationStatus[]; // ordered list of annotations on the page
	inlineCommentsDataMap: Record<string, CommentData>; // source of truth that maps annotation to a comment thread
	removedAnnotations: Record<number, string>; // maps annotation position on page to annotation ID, used to show the removed comment thread in the view
	totalCommentCount: number;
};

export const initialState: CommentsDataState = {
	orderedActiveAnnotationIdList: [],
	inlineCommentsDataMap: {},
	removedAnnotations: {},
	totalCommentCount: 0,
};

export enum UnreadAction {
	READ,
	UNREAD,
}

export enum CommentActionType {
	CREATE_COMMENT = 'created',
	DELETE_COMMENT = 'deleted',
	RESOLVE_COMMENT_THREAD = 'resolved',
	REOPEN_COMMENT_THREAD = 'reopened',
	REATTACH_COMMENT = 'reattach',
}

export const actions = {
	setInitialCommentCountOnLoad:
		(count: number) =>
		({ setState }: StoreActionApi<CommentsDataState>) => {
			setState({ totalCommentCount: count });
		},
	setInlineCommentsDataMap:
		(newCommentsDataMap: Record<string, CommentData>) =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { orderedActiveAnnotationIdList } = getState();

			const parentCommentMarkerRefs = new Set(Object.keys(newCommentsDataMap));

			// Update the loaded status of annotations based on newCommentsDataMap
			const updatedAnnotationList = orderedActiveAnnotationIdList.map((annotation) => ({
				...annotation,
				isLoaded: parentCommentMarkerRefs.has(annotation.annotationId),
			}));

			setState({
				inlineCommentsDataMap: newCommentsDataMap,
				orderedActiveAnnotationIdList: updatedAnnotationList,
			});
		},
	setOrderedActiveAnnotationIdList:
		(newCommentsList: string[]) =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { orderedActiveAnnotationIdList } = getState();
			if (!newCommentsList || newCommentsList.length === 0) {
				// Only update state if the current list is not already empty
				if (orderedActiveAnnotationIdList.length > 0) {
					setState({ orderedActiveAnnotationIdList: [] });
				}
				return;
			}

			// Create a map of existing annotationId to their isLoaded states for quick lookup
			const existingAnnotationsMap = new Map(
				orderedActiveAnnotationIdList.map(({ annotationId, isLoaded }) => [annotationId, isLoaded]),
			);
			// Use a Set to ensure unique annotation IDs
			const uniqueCommentsSet = new Set(newCommentsList);
			// Map the unique comments set to the new state, preserving isLoaded where possible
			const updatedAnnotationList = Array.from(uniqueCommentsSet).map((annotationId) => ({
				annotationId,
				isLoaded: existingAnnotationsMap.get(annotationId) ?? false, // Preserve existing isLoaded or default to false
			}));

			if (!isEqual(orderedActiveAnnotationIdList, updatedAnnotationList)) {
				setState({
					orderedActiveAnnotationIdList: updatedAnnotationList,
				});
			}
		},
	updateReactionsSummary:
		(emojiId: string, actionType: 'add' | 'delete', commentId: string, annotationId?: string) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();

			if (!annotationId) {
				return;
			}

			const comment: CommentData | undefined = inlineCommentsDataMap[annotationId];
			let reply: ReplyData | undefined;

			// If the comment doesn't exist in the map, just return
			if (!comment) {
				return;
			}

			// If the comment IDs don't match, this is a reply, find it and use that
			if (comment.id !== commentId) {
				reply = comment.replies.find((r: ReplyData) => r.id === commentId);

				// If we don't find the reply after that, just return
				if (!reply) {
					return;
				}
			}

			let reactionsSummary;

			if (reply) {
				reactionsSummary = reply.reactionsSummary;
			} else {
				reactionsSummary = comment.reactionsSummary;
			}

			const reactionsAri = reactionsSummary?.ari || '';
			const reactionsContainerAri = reactionsSummary?.containerAri || '';
			const reactionsNodes = reactionsSummary?.reactionsSummaryForEmoji || [];
			let reactionsCount = reactionsSummary?.reactionsCount || 0;

			const updatedReactionsNodes = [...reactionsNodes];
			const updatedReactionsSummary = { ...reactionsSummary };

			if (actionType === 'add') {
				const reaction = updatedReactionsNodes.find((item) => item?.emojiId === emojiId);

				// If reaction exists, update its attributes
				if (reaction && !reaction.reacted) {
					reaction.count++;
					reaction.reacted = true;
				} else {
					// Otherwise, create a new reaction
					const newReaction = {
						emojiId,
						count: 1,
						reacted: true,
						id: `${reactionsContainerAri}|${reactionsAri}|${emojiId}`,
					};

					updatedReactionsNodes.push(newReaction);
				}

				++reactionsCount;
			} else if (actionType === 'delete') {
				const reactionIdx = updatedReactionsNodes.findIndex((item) => item?.emojiId === emojiId);

				// If reaction exists, update its attributes
				if (reactionIdx !== -1) {
					const reaction = updatedReactionsNodes[reactionIdx];

					// If we have more than one reaction, remove our user from it and decrement the count
					if (reaction && reaction.count > 1) {
						reaction.count--;
						reaction.reacted = false;
					} else {
						// Otherwise, remove the reaction entirely
						updatedReactionsNodes.splice(reactionIdx, 1);
					}
				}

				--reactionsCount;
			}

			updatedReactionsSummary.reactionsSummaryForEmoji = updatedReactionsNodes;
			updatedReactionsSummary.reactionsCount = reactionsCount;

			if (reply) {
				reply.reactionsSummary = updatedReactionsSummary as ReactionsSummary;
			} else {
				comment.reactionsSummary = updatedReactionsSummary as ReactionsSummary;
			}

			setState({
				inlineCommentsDataMap,
			});
		},
	updateUnreadStatus:
		(annotationWithCommentIDs: Record<string, Set<string>>, action: UnreadAction) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();

			const updatedMap = { ...inlineCommentsDataMap };

			// for each annotation and their comment IDs, update the unread status in the inlineCommentsDataMap map
			Object.entries(annotationWithCommentIDs).forEach(([annotationId, commentIdsToMark]) => {
				const commentData = updatedMap[annotationId];
				if (commentData) {
					const isParentCommentUnread = commentIdsToMark.has(commentData.id);
					updatedMap[annotationId] = {
						...commentData,
						isUnread: isParentCommentUnread ? action === UnreadAction.UNREAD : commentData.isUnread,
						replies: commentData.replies?.map((reply) => {
							// Update the reply's unread status if its ID is included in commentIdsToMark
							if (commentIdsToMark.has(reply.id)) {
								return {
									...reply,
									isUnread: action === UnreadAction.UNREAD,
								};
							}
							return reply;
						}),
						wasRemovedByAnotherUser: false,
					};
				}
			});

			setState({ inlineCommentsDataMap: updatedMap });
		},
	addNewCommentThreads:
		(newCommentThreads: Record<string, CommentData>) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap, orderedActiveAnnotationIdList } = getState();

			const updatedCommentsDataMap = { ...inlineCommentsDataMap };

			// Add the comment threads
			for (const parentMarkerRef in newCommentThreads) {
				const newCommentThread = newCommentThreads[parentMarkerRef];
				updatedCommentsDataMap[parentMarkerRef] = newCommentThread;
			}

			// Update the loaded status of annotations
			const updatedAnnotationList = orderedActiveAnnotationIdList.map((annotation) => ({
				...annotation,
				isLoaded: Object.keys(newCommentThreads).includes(annotation.annotationId)
					? true
					: annotation.isLoaded,
			}));

			setState({
				inlineCommentsDataMap: updatedCommentsDataMap,
				orderedActiveAnnotationIdList: updatedAnnotationList,
			});
		},
	addReplyToCommentThread:
		(parentMarkerRef: string, reply: ReplyData) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();
			const parentCommentThread = inlineCommentsDataMap[parentMarkerRef];
			if (parentCommentThread) {
				// due to the nature of how we are sending multiple pubsub events are being sent for comment creation, we have to check for a duplicate before adding a reply
				const replyExists = parentCommentThread.replies?.some(
					(existingReply) => existingReply.id === reply.id,
				);
				if (!replyExists) {
					parentCommentThread.replies.push(reply);
					setState({
						inlineCommentsDataMap: {
							...inlineCommentsDataMap,
							[parentMarkerRef]: parentCommentThread,
						},
					});
				}
			}
		},
	updateCommentBody:
		(commentId: string, annotationId: string, newBodyValue: string) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			// Find the comment in the map
			const { inlineCommentsDataMap } = getState();

			// Make a copy of the map so if looks like a new object in shallow comparisons and also
			// avoids mutating the original map
			const updatedMap = { ...inlineCommentsDataMap };

			// The "parentComment" is either the comment itsef (if a top-level comment) or the parent of
			// the reply thread
			const parentComment = inlineCommentsDataMap[annotationId];
			if (parentComment) {
				// If the modified comment is a top-level comment, update the body value
				if (parentComment.id === commentId) {
					// Make a copy of the comment and update the body value, then put it back into the map
					const updatedComment = {
						...parentComment,
						body: { value: newBodyValue },
					};
					updatedMap[annotationId] = updatedComment;
					setState({ inlineCommentsDataMap: updatedMap });
				} else {
					// Else we didn't find the comment at the top-level, check the replies
					for (let replyIndex = 0; replyIndex < parentComment.replies.length; replyIndex++) {
						const reply = parentComment.replies[replyIndex];
						if (reply.id === commentId) {
							// Make a copy of the comment and update the reply's body value, then put it back into the map
							const updatedReply = {
								...reply,
								body: { value: newBodyValue },
							};
							// (A reminder that ... does a shallow copy, so we need to copy the replies array as well)
							const updatedComment = {
								...parentComment,
								replies: [...parentComment.replies],
							};
							updatedComment.replies[replyIndex] = updatedReply;
							updatedMap[annotationId] = updatedComment;
							setState({ inlineCommentsDataMap: updatedMap });
							break;
						}
					}
				}
			}
		},
	// keep track of removed annotations as well as update removed comments with wasRemovedByAnotherUser
	updateRemovedCommentIdsMap:
		({
			parentMarkerRef,
			commentId,
			commentActionType,
			isReply,
		}: {
			parentMarkerRef: string;
			commentId: string;
			commentActionType:
				| CommentActionType.DELETE_COMMENT
				| CommentActionType.RESOLVE_COMMENT_THREAD;
			isReply: boolean;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap, orderedActiveAnnotationIdList, removedAnnotations } =
				getState();
			const updatedInlineCommentsDataMap = { ...inlineCommentsDataMap };
			if (parentMarkerRef && updatedInlineCommentsDataMap[parentMarkerRef]) {
				if (isReply) {
					const updatedCommentThreadReplies = updatedInlineCommentsDataMap[
						parentMarkerRef
					].replies.map((r) => {
						if (r.id === commentId) {
							return {
								...r,
								wasRemovedByAnotherUser: commentActionType,
							};
						}
						return r;
					});
					updatedInlineCommentsDataMap[parentMarkerRef] = {
						...updatedInlineCommentsDataMap[parentMarkerRef],
						replies: updatedCommentThreadReplies,
					};
				} else {
					updatedInlineCommentsDataMap[parentMarkerRef] = {
						...updatedInlineCommentsDataMap[parentMarkerRef],
						isOpen: false,
						wasRemovedByAnotherUser: commentActionType,
					};
				}

				// since orderedActiveAnnotationIdList will not include previously removed annotations, we need to add in annotations in removedAnnotations
				// so that we can correctly find the index of the removed annotation
				const combinedAnnotationList = [
					...orderedActiveAnnotationIdList.map((a) => a.annotationId),
				];

				Object.entries(removedAnnotations).forEach(([index, annotationId]) => {
					const position = parseInt(index, 10); // Ensure the index is treated as a number
					combinedAnnotationList.splice(position, 0, annotationId);
				});

				const annotationIdx = getAnnotationPosition(parentMarkerRef, combinedAnnotationList);
				setState({
					inlineCommentsDataMap: updatedInlineCommentsDataMap,
					removedAnnotations: {
						...removedAnnotations,
						[annotationIdx]: parentMarkerRef,
					},
				});
			}
		},
	updateCommentCount:
		({
			parentMarkerRef,
			actionType,
			commentCountWithReplies,
		}: {
			parentMarkerRef: string;
			actionType: CommentActionType;
			commentCountWithReplies?: number;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap, totalCommentCount } = getState();
			switch (actionType) {
				case CommentActionType.CREATE_COMMENT:
				case CommentActionType.REATTACH_COMMENT:
					setState({ totalCommentCount: totalCommentCount + 1 });
					break;
				case CommentActionType.DELETE_COMMENT:
					setState({ totalCommentCount: totalCommentCount - 1 });
					break;

				case CommentActionType.RESOLVE_COMMENT_THREAD:
					//TODO: For pubsub, we need to do it from editor
					const commentThreadCount = inlineCommentsDataMap[parentMarkerRef]
						? inlineCommentsDataMap[parentMarkerRef].replies.length + 1
						: commentCountWithReplies;
					setState({
						totalCommentCount: totalCommentCount - (commentThreadCount || 0),
					});
					break;
				case CommentActionType.REOPEN_COMMENT_THREAD:
					setState({
						totalCommentCount:
							totalCommentCount + (inlineCommentsDataMap[parentMarkerRef].replies.length + 1),
					});
					break;
				default:
					// Optionally handle unexpected events or do nothing
					break;
			}
		},
	handleRemovingComments:
		({
			parentMarkerRef,
			commentId,
			action,
		}: {
			parentMarkerRef: string;
			commentId?: string;
			action: CommentActionType;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();
			const parentCommentThread = inlineCommentsDataMap[parentMarkerRef];

			if (parentCommentThread) {
				if (
					action === CommentActionType.RESOLVE_COMMENT_THREAD &&
					parentCommentThread.id === commentId
				) {
					// If the action is to resolve the thread and the commentId matches the parent, remove the thread
					const updatedCommentsDataMap = { ...inlineCommentsDataMap };
					delete updatedCommentsDataMap[parentMarkerRef];
					setState({
						inlineCommentsDataMap: updatedCommentsDataMap,
					});
				} else if (action === CommentActionType.DELETE_COMMENT) {
					// If the action is to delete a reply, filter out the reply with the specified commentId
					const updatedReplies = parentCommentThread.replies.filter(
						(reply) => reply.id !== commentId,
					);
					// Update the parent comment thread with the filtered replies
					setState({
						inlineCommentsDataMap: {
							...inlineCommentsDataMap,
							[parentMarkerRef]: {
								...parentCommentThread,
								replies: updatedReplies,
							},
						},
					});
				}
			}
		},
	deleteParentComment:
		({ parentMarkerRef }: { parentMarkerRef: string }) =>
		({ setState, getState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();
			const parentCommentThread = inlineCommentsDataMap[parentMarkerRef];

			if (parentCommentThread) {
				const updatedCommentsDataMap = { ...inlineCommentsDataMap };
				delete updatedCommentsDataMap[parentMarkerRef];
				setState({
					inlineCommentsDataMap: updatedCommentsDataMap,
				});
			}
		},
	clearRemovedComments:
		() =>
		({ getState, setState }: StoreActionApi<CommentsDataState>) => {
			const { inlineCommentsDataMap } = getState();

			// filter out deleted / resolved comments
			const updatedInlineCommentsDataMap = Object.fromEntries(
				Object.entries(inlineCommentsDataMap)
					.filter(([_, commentData]) => !commentData.wasRemovedByAnotherUser)
					.map(([key, commentData]) => [
						key,
						{
							...commentData,
							replies: commentData.replies?.filter((reply) => !reply.wasRemovedByAnotherUser),
						},
					]),
			);

			setState({ removedAnnotations: {}, inlineCommentsDataMap: updatedInlineCommentsDataMap });
		},
	clearCommentsData:
		() =>
		({ setState }: StoreActionApi<CommentsDataState>) => {
			setState(initialState);
		},
};

export const CommentsDataStore = createStore({
	initialState,
	actions,
	name: 'CommentsDataStore',
});

export const useCommentsData = createHook(CommentsDataStore);
export const useCommentsDataActions = createActionsHook(CommentsDataStore);
export const useCommentsDataState = createStateHook(CommentsDataStore);
