/* eslint-disable import/no-extraneous-dependencies */
import type {
	ActiveInlineCommentsQueryType,
	CommentLocation,
	CommentReply,
} from '@confluence/inline-comments-queries';
import { isValidUnreadComment } from '@confluence/unread-comments';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';

import type { CommentData } from '../useCommentsData';

export const updateCommentsDataState = ({
	data,
	readCommentsListState,
	annotationsInEditorDoc,
	pageMode,
	currentUserId,
	updateData,
	isOpen = true,
}: {
	data: ActiveInlineCommentsQueryType;
	readCommentsListState: Set<string>;
	annotationsInEditorDoc: Set<string>;
	pageMode: PageMode;
	currentUserId: string;
	updateData: (newCommentsDataMap: Record<string, CommentData>) => void;
	isOpen: boolean;
}) => {
	const commentThreadsToAdd: Record<string, CommentData> = {};

	data?.comments?.nodes?.forEach((comment) => {
		if (comment) {
			const markerRef = (comment.location as CommentLocation).inlineMarkerRef;
			if (!comment.reactionsSummary) {
				comment.reactionsSummary = null;
			}

			if (markerRef) {
				commentThreadsToAdd[markerRef] = {
					...comment,
					isUnread: !!isValidUnreadComment({
						comment,
						viewedComments: readCommentsListState,
						userId: currentUserId,
						annotationsInEditorDoc,
						pageMode: pageMode as 'view' | 'edit',
					}),
					wasRemovedByAnotherUser: false,
					isOpen,
					replies: (comment.replies as CommentReply[]).map((reply) => {
						if (!reply.reactionsSummary) {
							reply.reactionsSummary = null;
						}

						return {
							...reply,
							isUnread: !!isValidUnreadComment({
								comment: reply,
								viewedComments: readCommentsListState,
								userId: currentUserId,
								annotationsInEditorDoc,
								pageMode: pageMode as 'view' | 'edit',
							}),
							wasRemovedByAnotherUser: false,
						};
					}),
				};
			}
		}
	});

	updateData(commentThreadsToAdd);
};

export const getAnnotationPosition = (annotationId: string, annotations: string[]): number => {
	return annotations.findIndex((annotation) => annotation === annotationId);
};
